// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accountabilibuddies-js": () => import("./../src/pages/accountabilibuddies.js" /* webpackChunkName: "component---src-pages-accountabilibuddies-js" */),
  "component---src-pages-candle-js": () => import("./../src/pages/candle.js" /* webpackChunkName: "component---src-pages-candle-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polaroll-js": () => import("./../src/pages/polaroll.js" /* webpackChunkName: "component---src-pages-polaroll-js" */),
  "component---src-pages-resume-js": () => import("./../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

